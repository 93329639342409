'use client';

import { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { ERROR_CODE, isNormalizedResponse } from '@wello-client/common/utils';
import { random } from 'lodash-es';
import Image from 'next/image';

import { Button } from '@/components/server';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';

import styles from './error.module.scss';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

const ERROR_IMG_PROPS = {
  alt: 'Error',
  className: styles.image,
  height: 186,
  width: 335,
} as const;

const ErrorPage = ({ error, reset }: ErrorPageProps) => {
  const router = useCustomRouter();
  const [errorId, setErrorId] = useState('');

  const errorCode = isNormalizedResponse(error) ? error.return_code : undefined;

  useEffect(() => {
    if (errorCode && [ERROR_CODE.HIDDEN].includes(errorCode)) return;

    const id = Sentry.captureException(error);

    setErrorId(id);
  }, [error, errorCode]);

  //! ⚠️ 2번 렌더링 되는것을 방지하기 위함
  const imgIndex = useRef(0);

  return (
    <article className={styles['error-page']}>
      {(() => {
        switch (errorCode) {
          case ERROR_CODE.HIDDEN:
            if (!imgIndex.current) {
              imgIndex.current = random(1, 2);
            }

            return (
              <>
                <section className={styles['error-contents']}>
                  <h1 className={styles.title}>
                    해당 페이지를
                    <br />볼 수 없어요
                  </h1>
                  <p className={styles['description']}>
                    마감 기간이 지났거나
                    <br />
                    이용 정책에 의해 비공개 처리된 페이지에요.
                  </p>
                  <p className={styles['description']}>
                    다른 페이지를 이용해 주세요.
                  </p>
                  <Image
                    {...ERROR_IMG_PROPS}
                    src={`/assets/pngs/error/hidden/${imgIndex.current}.png`}
                  />
                </section>
                <nav className={styles.nav}>
                  <Button theme="sub-outline" onClick={() => router.back()}>
                    돌아가기
                  </Button>
                  <Button onClick={() => router.push(ROUTES.HOME.pathname)}>
                    홈으로 이동
                  </Button>
                </nav>
              </>
            );

          default:
            if (!imgIndex.current) {
              imgIndex.current = random(1, 2);
            }

            return (
              <>
                <section className={styles['error-contents']}>
                  <h1 className={styles.title}>
                    생각지 못한 오류가
                    <br />
                    발생했어요
                  </h1>
                  <p className={styles['description']}>
                    새로 고침을 선택하거나 홈으로 이동해 주세요.
                  </p>
                  <p className={styles['description']}>
                    반복되는 오류는 고객 문의로 문의해 주시면
                    <br />
                    빠르게 처리해 드리겠습니다.
                  </p>
                  <p className={styles['error-code']}>{errorId}</p>
                  <Image
                    {...ERROR_IMG_PROPS}
                    src={`/assets/pngs/error/${imgIndex.current}.png`}
                  />
                </section>
                <nav className={styles.nav}>
                  <Button theme="sub-outline" onClick={reset}>
                    새로고침
                  </Button>
                  <Button onClick={() => router.push(ROUTES.HOME.pathname)}>
                    홈으로 이동
                  </Button>
                </nav>
              </>
            );
        }
      })()}
    </article>
  );
};

export default ErrorPage;
